import {
  Box,
  Button,
  FileInput,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCheck,
  IconFileDownload,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { isNullOrUndefined } from "../../../../_base/extension/StringExtension";
import Repository from "../../../../_base/helper/HttpHelper";
import { Updatefilexcel } from "../../../../api/ApiAddress";
import { MessageResponse } from "../../../../model/MessageResponse";

const UpdateExel = ({ id, onSearch }: any) => {
  const entity = {
    FormFile: "", // Đổi từ "" thành null
  };

  const [file, setFile] = useState();

  const handleCreateProvince = async (dataSubmit: DataProvince) => {
    const formData = new FormData();
    console.log("11111111111", dataSubmit.FormFile);
    console.log("222222222", dataSubmit.FormFile?.File);

    // Kiểm tra nếu FormFile không phải là null trước khi thêm vào formData
    if (dataSubmit.FormFile) {
      formData.append("FormFile", dataSubmit.FormFile); // thêm file vào FormData
    } else {
      NotificationExtension.Fails("Vui lòng chọn một file để cập nhật."); // Thêm thông báo nếu không có file
      return; // Dừng lại nếu không có file
    }
    const response = await Updatefilexcel(formData);

    if (response?.success) {
      modals.closeAll();
      NotificationExtension.Success("Cập nhật thành công"); // Thêm thông báo thành công
    } else {
      NotificationExtension.Fails("Cập nhật thất bại"); // Thêm thông báo thất bại
    }

    onSearch();
  };

  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<DataProvince>({
    initialValues: {
      ...entity,
    },
    validate: {
      FormFile: (value) => (value ? null : "Vui lòng chọn một file"),
    },
  });

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  const callApiGetData = async () => {
    open();
    const urlDetail = `/TblItem/update-excel`;
    let callApi = await repository.post<MessageResponse<DataProvince>>(
      urlDetail
    );
    if (!isNullOrUndefined(callApi) && !isNullOrUndefined(callApi?.data)) {
      const dataApi = callApi?.data;
      if (dataApi != null && !isNullOrUndefined(dataApi)) {
        form.setValues(dataApi);
      } else {
        NotificationExtension.Fails("Dữ liệu không tồn tại");
        modals.closeAll();
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const handleFileChange = (file: any) => {
    // Xử lý tệp được chọn (nếu cần)
    setFile(file); // Bạn có thể thay đổi hành động này theo yêu cầu của bạn
  };
  // Exxport sản phẩm theo form giá
  const handleExport = async () => {
    try {
      let _url = `https://apis-dev.hacom.vn/api-web-service/api/v1/ExportExcel/export-bang-gia
`;
      const fullUrl = new URL(_url);
      const token = localStorage.getItem("token");
      fetch(fullUrl.toString(), {
        method: "GET", // Use the appropriate HTTP method
        headers: {
          Authorization: `Bearer ${token?.replace(/"/g, "")}`,
          "Content-Type": "application/vnd.ms-excel",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.blob(); // Assuming the response is a file or blob
          } else {
            throw new Error("Failed to fetch the resource");
          }
        })

        .then((blob) => {
          // Open the blob in a new tab
          const url = window.URL.createObjectURL(blob);
          let filename = "Export_san_pham_theo_form_gia.xlsx"; // Tên mặc định nếu không có header
          const link = document.createElement("a");
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          NotificationExtension.Success("Export excel thành công !");
        })
        .catch((error) => {
          console.error("Error:", error);
          NotificationExtension.Fails("Export excel thất bại !");
        });
    } catch (error) {
      NotificationExtension.Fails("Export excel thất bại !");
    }
  };
  return (
    <Box
      className="flex-none"
      component="form"
      miw={600}
      maw={600}
      mx="auto"
      onSubmit={form.onSubmit((e: DataProvince) => {
        handleCreateProvince(e);
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <div
        style={{
          marginTop: "7px",
        }}
      >
        <Button
          leftSection={<IconFileDownload size={14}></IconFileDownload>}
          onClick={() => handleExport()}
          color="green"
          variant="outline"
        >
          Export Excel
        </Button>
      </div>
      <Group grow wrap="nowrap" mt="0" gap={"lg"}>
        <FileInput
          label="Nhập File"
          description="Upload Excel files (.xls, .xlsx)"
          placeholder="Select a file"
          mt="md"
          accept=".xls,.xlsx"
          {...form.getInputProps("FormFile")}
          rightSection={<IconUpload />}
        />
      </Group>
      <Group justify="flex-end" mt="lg">
        <Button
          type="submit"
          color="#3598dc"
          loading={visible}
          leftSection={<IconCheck size={18} />}
        >
          Cập Nhật
        </Button>
        <Button
          variant="outline"
          color="black"
          type="button"
          loading={visible}
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Đóng
        </Button>
      </Group>
    </Box>
  );
};

type DataProvince = {
  FormFile: any; // Thay đổi kiểu thành File | null
};

export default UpdateExel;
