import {
  Box,
  Button,
  Divider,
  FileInput,
  Flex,
  Group,
  LoadingOverlay,
  Text,
  TextInput,
  Tooltip,
  rem,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconFileCv } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { TblItemImageCommand } from "../../../../model/ProductList";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { urlToImageFile } from "../../../../_base/helper/FunctionHelper";
import ImageShow from "../../../../_base/component/_image";
import { uploadImage } from "../../../../api/ApiProduct";
import { useDisclosure } from "@mantine/hooks";

const ItemImageCommand = ({
  dataItemImage,
  onAddItemImage,
}: ItemImageProps) => {
  const [groupCount, setGroupCount] = useState(1); // Số lượng ô Group hiện tại
  const [dataImage, setDataImage] = useState<TblItemImageCommand[]>([]); // Lưu giữ giá trị của TextInput cho từng Group
  const [isImageChange, setIsImageChange] = useState<boolean[]>([]);
  const [isImageWidth, setIsImageWidth] = useState<number | undefined>(
    undefined
  );
  const [visible, { toggle, open, close }] = useDisclosure(false);

  const handleAddGroup = () => {
    setGroupCount((prevCount) => prevCount + 1);
    setDataImage((prevData) => [
      ...prevData,
      {
        image: "",
        imageCode: "",
        imageName: "",
        orderNumber: 0,
        primaryImg: "N",
      },
    ]);
  };

  const icon = (
    <IconFileCv style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  const handleRemoveGroup = (index: number) => {
    setGroupCount((prevCount) => prevCount - 1);
    const newDataImage = [...dataImage];
    newDataImage.splice(index, 1);
    setDataImage(newDataImage);
  };

  const handleChangeValue = (
    value: string | number | File | null,
    index: number,
    field: string
  ) => {
    const newDataImage = [...dataImage];
    newDataImage[index] = {
      ...newDataImage[index],
      [field]: value,
    };

    if (field === "image") {
      setIsImageChange((prevData) => {
        const newData = [...prevData];
        newData[index] = true;
        return newData;
      });
    }

    setDataImage(newDataImage);
  };

  const handleChangeMainImage = (count: number) => {
    const newDataImage = dataImage?.map((item, index) => {
      if (index === count) {
        return { ...item, primaryImg: "Y" };
      } else {
        return { ...item, primaryImg: "N" };
      }
    });

    setDataImage(newDataImage);
  };

  const handleRemoveMainImage = (index: number) => {
    const newDataImage = [...dataImage];
    newDataImage[index] = {
      ...newDataImage[index],
      primaryImg: "N",
    };
    setDataImage(newDataImage);
  };

  const handleSaveForm = async () => {
    const hasPrimaryImage = dataImage.some((image) => image.primaryImg === "Y");
    if (dataImage.length !== 0 && !hasPrimaryImage) {
      NotificationExtension.Fails(
        "Vui lòng chọn một ảnh làm ảnh chính trước khi lưu"
      );
      return;
    } else {
      const dataUploadImage = dataImage.map((item) => ({
        Width: item.imageWidth,
        Height: item.imageHeight,
        image: item.image,
        TableName: "tblItem",
      }));

      open();
      const res = await uploadImage(dataUploadImage);
      console.log(res, "check");
      const urlImage = res.data;
      if (res?.success && typeof urlImage === "string") {
        console.log(urlImage);
        const newDataImage = dataImage.map((item) => ({
          ...item,
          image: urlImage,
        }));
        onAddItemImage(newDataImage, isImageChange);
      }
      close();
    }
  };

  const getObjectURL = (image: string | File | null): string => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    } else return image as string;
  };

  const valueFileInput = (image: string | File | null): File | null => {
    if (image instanceof File) {
      return image;
    } else return null;
  };

  useEffect(() => {
    const getDataImage = async () => {
      if (dataItemImage) {
        const updatedDataImage = await Promise.all(
          dataItemImage.map(async (item) => {
            return item;
          })
        );
        setDataImage(updatedDataImage);
        setGroupCount(dataItemImage.length);
      }
    };

    getDataImage();
  }, [dataItemImage]);

  const getWidthImage = (url: string): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.addEventListener("load", function () {
        const width = this.width;
        resolve(width);
      });
      img.addEventListener("error", function () {
        reject(new Error("Failed to load image."));
      });
    });
  };

  return (
    <Box
      className="flex-none"
      component="form"
      miw={1200}
      maw={1200}
      mx="auto"
      style={{ position: "relative" }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Text mt={24} fw={600}>
        Chú ý :
      </Text>
      <Text fw={400}>
        - Ảnh Sản phẩm kích thước lớn sẽ được tự động co lại thành các ảnh nhỏ
        hơn cho những vị trí liên quan. <br />
        Tùy giao diện website của bạn mà kích thước ảnh Sản phẩm khác nhau
        <br />
        - Chỉ dùng file ảnh đuôi .jpg và .gif. <br />- Cập nhật hình ảnh cho Sản
        phẩm ở nhiều góc cạnh, màu sắc để người dùng xem rõ nhất. Nên cập nhật
        ảnh có kích thước lớn và độ phân giải cao.
      </Text>
      <Button mt={24} type="button" color="#3598dc" onClick={handleAddGroup}>
        Thêm ảnh
      </Button>

      {/* {[...Array(groupCount)].map((_, index) => (
        <>
          <Group key={index} grow wrap="nowrap" mt="xs" gap={"lg"}>
            <FileInput
              leftSection={icon}
              label="Ảnh sản phẩm"
              placeholder="Chọn tệp..."
              leftSectionPointerEvents="none"
              accept="image/png,image/jpeg"
              value={valueFileInput(dataImage[index]?.image)}
              onChange={(e) => handleChangeValue(e, index, "image")}
            />
            {dataImage[index]?.image ? (
              <ImageShow
                h={200}
                w={200}
                img={getObjectURL(dataImage[index]?.image)}
              />
            ) : (
              <Box></Box>
            )}
          </Group>

          <Group key={index} grow wrap="nowrap" mt="xs" gap={"lg"}>
            <TextInput
              label="Mã ảnh"
              placeholder="Mã ảnh..."
              type="text"
              onChange={(e) =>
                handleChangeValue(e.target.value, index, "imageCode")
              }
              value={dataImage[index]?.imageCode?.toString()}
            />

            {dataImage[index]?.primaryImg! === "Y" ? (
              <Button
                mt={"24px"}
                style={{ maxWidth: 130 }}
                type="button"
                color="red"
                onClick={() => handleRemoveMainImage(index)}
              >
                Bỏ ảnh chính
              </Button>
            ) : (
              <Button
                mt={"24px"}
                style={{ maxWidth: 130 }}
                type="button"
                color="blue"
                onClick={() => handleChangeMainImage(index)}
              >
                Chọn ảnh chính
              </Button>
            )}
          </Group>

          <Group key={index} grow wrap="nowrap" mt="xs" gap={"lg"}>
            <TextInput
              label="Alt của ảnh"
              placeholder="Alt của ảnh..."
              type="text"
              onChange={(e) =>
                handleChangeValue(e.target.value, index, "imageName")
              }
              value={dataImage[index]?.imageName?.toString()}
            />

            <Button
              mt={"24px"}
              style={{ maxWidth: 80 }}
              type="button"
              color="red"
              onClick={() => handleRemoveGroup(index)}
            >
              Xóa
            </Button>
          </Group>
          <Group key={index} grow wrap="nowrap" mt="xs" gap={"lg"}>
            <TextInput
              label="STT"
              placeholder="Nhập số STT"
              type="number"
              onChange={(e) =>
                handleChangeValue(e.target.value, index, "orderNumber")
              }
              value={dataImage[index]?.orderNumber || 0}
            />
            <></>
          </Group>
          <Group key={index} grow wrap="nowrap" mt="xs" gap={"lg"}>
            <TextInput
              label="Width"
              placeholder="Nhập chiều rộng của ảnh"
              type="number"
              onChange={(e) =>
                handleChangeValue(e.target.value, index, "imageWidth")
              }
              value={dataImage[index]?.imageWidth || 0}
            />
            <TextInput
              label="Height"
              placeholder="Nhập chiều cao của ảnh"
              type="number"
              onChange={(e) =>
                handleChangeValue(e.target.value, index, "imageHeight")
              }
              value={dataImage[index]?.imageHeight || 0}
            />
          </Group>
          <Divider style={{ marginTop: 24 }} />
        </>
      ))} */}
      <Flex
        gap="sm"
        justify="flex-start"
        align="flex-start"
        direction="row"
        wrap="wrap"
      >
        {[...Array(groupCount)].map((_, index) => (
          <div
            style={{
              border: "1px solid #e9ecef",
              borderRadius: "0.5rem",
              padding: "1rem",
              maxWidth: 200,
            }}
          >
            <Group key={index} grow wrap="nowrap">
              <Flex direction={"column"}>
                <div
                  style={{
                    width: 170,
                    height: 100,
                    objectFit: "cover",
                    overflow: "hidden",
                    textAlign: "center",
                  }}
                >
                  {dataImage[index]?.image ? (
                    <ImageShow
                      h={100}
                      w={170}
                      img={getObjectURL(dataImage[index]?.image)}
                    />
                  ) : (
                    <Box></Box>
                  )}
                </div>
                <FileInput
                  leftSection={icon}
                  label="Ảnh sản phẩm"
                  placeholder="Chọn tệp..."
                  leftSectionPointerEvents="none"
                  accept="image/png,image/jpeg"
                  value={valueFileInput(dataImage[index]?.image)}
                  onChange={(e) => handleChangeValue(e, index, "image")}
                />
              </Flex>
            </Group>

            <Group key={index} grow wrap="nowrap" gap={"lg"}>
              <TextInput
                label="Mã ảnh"
                placeholder="Mã ảnh..."
                type="text"
                onChange={(e) =>
                  handleChangeValue(e.target.value, index, "imageCode")
                }
                value={dataImage[index]?.imageCode?.toString()}
              />
              <Tooltip label={dataImage[index]?.imageName?.toString() || ""}>
                <TextInput
                  label="Alt của ảnh"
                  placeholder="Alt của ảnh..."
                  type="text"
                  onChange={(e) =>
                    handleChangeValue(e.target.value, index, "imageName")
                  }
                  value={dataImage[index]?.imageName?.toString()}
                />
              </Tooltip>
            </Group>

            <Group key={index} grow wrap="nowrap" gap={"lg"}>
              {dataImage[index]?.primaryImg! === "Y" ? (
                <Tooltip label=" Bỏ ảnh chính">
                  <Button
                    mt={"24px"}
                    style={{ width: 50 }}
                    type="button"
                    color="red"
                    onClick={() => handleRemoveMainImage(index)}
                  >
                    Bỏ ảnh chính
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip label="Chọn ảnh chính">
                  <Button
                    mt={"24px"}
                    style={{ width: 50 }}
                    type="button"
                    color="blue"
                    onClick={() => handleChangeMainImage(index)}
                  >
                    Chọn ảnh chính
                  </Button>
                </Tooltip>
              )}
              <Button
                mt={"24px"}
                style={{ width: 50 }}
                type="button"
                color="red"
                onClick={() => handleRemoveGroup(index)}
              >
                Xóa
              </Button>
            </Group>

            <Divider style={{ marginTop: 24 }} />
          </div>
        ))}
      </Flex>
      <Group
        justify="flex-end"
        mt="lg"
        p="10px 0"
        style={{ position: "sticky", bottom: 45, backgroundColor: "white" }}
      >
        <Button
          type="button"
          color="#3598dc"
          onClick={() => {
            handleSaveForm();
          }}
        >
          Lưu
        </Button>

        <Button
          variant="outline"
          color="black"
          type="button"
          onClick={() => modals.closeAll()}
        >
          Đóng
        </Button>
      </Group>
    </Box>
  );
};

export default ItemImageCommand;

type ItemImageProps = {
  dataItemImage: TblItemImageCommand[] | null;
  onAddItemImage: (
    data: TblItemImageCommand[],
    isImageChange: boolean[]
  ) => void;
};
